import { styled } from "styled-components";

export const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;

  @media (min-width: 600px) {
    display: none;
  }
`;

export const Title = styled.h1`
  z-index: 2;
  background: var(
    --Gradient-text,
    linear-gradient(273deg, #3eb2dc 1.89%, #3daede 1.9%, #8befd2 102.52%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-family: Work Sans;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.8px;
  max-width: 345px;
  margin: 0;

  @media (min-width: 600px) {
    max-width: 1200px;
    text-align: center;
  }
`;

export const Subtitle = styled.h3`
  color: #f2f0ff;
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 300px;
  text-align: center;
  margin: 0 auto;
  z-index: 2;

  @media (min-width: 600px) {
    max-width: 1200px;
    text-align: center;
    margin: 0;
    margin-left: -140px;
  }
`;
