import React, { useContext, useState } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import { RegisterContext } from "../context/register.context";
import { createCustomerOrder } from "@/api/query/order";
import { PLAN_TYPE } from "../register.constants";
import PayuIcon from "../svg/payou.logo.svg";
import Visa from "../icons/Visa blue.svg";
import MasterCard from "../icons/mastercard.svg";
import Blik from "../icons/blik.svg";
import Transfer from "../icons/przelewy.svg";
import { IpStatsContext } from "@/common/contexts/IpStatsContext";
import { IP_STATS_ENUM } from "@/common/enums/ipStatsEnum";
import Lock from "../icons/secured-lock.svg";
import CheckWhite from "../icons/check-white.svg";
import { english } from "@/common/data/english";

const Title = styled.h3`
  text-align: center;
  color: #05275d;
  font-family: Work Sans;
  font-size: 28px;
  margin: 20px 0;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;

  @media (max-width: 600px) {
    font-size: 22px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (max-width: 600px) {
    width: 320px;
  }
`;

const Info = styled.div`
  color: #0b2f45;
  width: 100%;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;

  @media (max-width: 600px) {
    width: 300px;
    text-align: center;
    color: #05275d;
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

const ActionWrapper = styled.form`
  display: flex;
  justify-content: center;
  gap: 30px;
  margin: 0px 0;

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
`;

const LetMailButton = styled.button`
  display: flex;
  width: 200px;
  padding: 15px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 16px;
  background: #3280fc;
  outline: none;
  border: none;
  cursor: pointer;
  color: #fff;
  /* Button */
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
  text-transform: uppercase;

  &:hover {
    transform: scale(1.1);
  }
`;

const PricesWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;

  @media (max-width: 600px) {
    display: none;
  }
`;

const FlexElement = styled.div`
  width: 150px;
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  color: #05275d;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  background: #e2fbf4;
  line-height: 140%;

  @media (min-width: 600px) {
    width: 200px;
    height: 220px;
    border-radius: 16px;
    align-items: center;
    gap: 10px;
  }
`;

const FlexElementMobile = styled.div`
  width: 85vw;
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #05275d;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  background: white;
  padding: 10px;
  height: 115px;
`;

const TextTop = styled.div`
  color: #05275d;
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.4px;
`;

const TextPrice = styled.p`
  color: #05275d;
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.4px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 0;

  h3 {
    color: #05d698;
    font-family: Work Sans;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.96px;
    margin: 0;
  }
`;

const TextBottom = styled.div`
  color: #05275d;
  text-align: center;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 0px;
`;

const Card = styled.div`
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  @media (min-width: 600px) {
    margin: 0 auto;
    overflow-y: auto;
  }
`;

const PaymentTypeWrapper = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff;
  min-width: 350px;

  @media (max-width: 600px) {
    background: transparent;
  }
`;

const PaymentTitle = styled.p`
  color: #05275d;
  font-family: "Work Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const PaymentText = styled.p`
  color: #05275d;
  text-align: left;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  min-width: 180px;
  margin: 0;

  @media (max-width: 600px) {
    background: white;
  }
`;

const Border = styled.div`
  height: 1px;
  width: 200px;
  background: rgba(79, 167, 180, 0.2);
  margin-top: -15px;

  @media (max-width: 600px) {
    margin: 0;
    width: 100px;
  }
`;

const PaymentElement = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  width: 190px;
  border-radius: 100px;
  background: #3280fc;
  padding: 10px 0;

  color: #fff;

  text-align: center;
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;

  &:hover {
    transition: all 100ms ease-in-out;
    transform: scale(1.04);
  }
`;

const PaymentIcon = styled.img`
  width: 60px;
`;

const MonthWrapper = styled.h3`
  color: #05275d;
  text-align: center;
  font-family: "Work Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  margin: 20px 0;

  @media (max-width: 600px) {
    margin: 0px;
    font-size: 20px;
    margin-top: 25px;
  }
`;

const FullPriceWrapper = styled.p`
  color: #05275d;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 0;

  @media (max-width: 600px) {
    text-align: center;
  }
`;

const NowUwillPay = styled.p`
  font-family: "Work Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;
  margin: 0;
`;

const Picker = styled.div`
  display: inline-flex;
  width: 150px;
  height: 40px;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  border: 1px solid #3280fc;
  background: #fff;

  color: #3280fc;

  text-align: center;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
  text-transform: uppercase;

  @media (max-width: 600px) {
    width: 120px;
  }

  @media (max-width: 600px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const PickerPicked = styled(Picker)`
  border-radius: 8px;
  border: 1px solid #3280fc;
  background: #3280fc;

  @media (max-width: 600px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const ElementTop = styled.div`
  border-radius: 22px;
  background: #3280fc;
  padding: 10px;
  margin-top: -20px;
  margin-bottom: -25px;

  color: #fff;
  font-family: "Work Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.24px;
  text-transform: uppercase;

  @media (max-width: 600px) {
    padding: 6px 26px;
  }
`;

const FullPriceWrapperPercent = styled.p`
  color: #05275d;

  text-align: center;
  font-family: "Work Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  text-decoration: line-through red;
  -webkit-text-decoration: line-through red;
  margin-top: -5px;
  margin-bottom: -15px;

  @media (max-width: 600px) {
    margin: 40px 0 0 0;
  }
`;

const PricesWrapperMobile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: 600px) {
    display: none;
  }
`;

export const CONFIG_DATA = {
  _1: {
    price: "49,99",
    months: "1 miesiąc",
    full_price: "49,99",
    label: "Bronze",
    type: PLAN_TYPE.BRONZE,
  },
  _2: {
    price: "39,99",
    months: "3 miesiące",
    full_price: "119,97",
    label: "Gold",
    type: PLAN_TYPE.GOLD,
  },
  _3: {
    price: "29,99",
    months: "6 miesięcy",
    full_price: "179,94",
    label: "Platinum",
    type: PLAN_TYPE.PLATINIUM,
  },
};

export const CONFIG_DATA_ARRAY = [
  {
    price: "49,99",
    months: "1 miesiąc",
    full_price: "49,99",
    label: "Bronze",
    type: PLAN_TYPE.BRONZE,
  },
  {
    price: "39,99",
    months: "3 miesiące",
    full_price: "119,97",
    label: "Gold",
    type: PLAN_TYPE.GOLD,
  },
  {
    price: "29,99",
    months: "6 miesięcy",
    full_price: "179,94",
    label: "Platinum",
    type: PLAN_TYPE.PLATINIUM,
  },
];

export const PAYMENT_TYPES = {
  PAYU: "payu",
  APPLE: "apple",
  GOOGLE: "google",
};

export const PAYMENT_FORMS = [
  {
    type: PAYMENT_TYPES.PAYU,
    text: "PayU",
    subText: "Karta płatnicza VISA lub MasterCard",
    icon: PayuIcon,
  },
];

const PlanView = () => {
  const [selectedOption, setSelectedOption] = useState(
    CONFIG_DATA[`_${localStorage.getItem("picked_plan") || 2}`]
  );
  const { setView, user, setPlan } = useContext(RegisterContext);
  const { handleSendIPstats } = useContext(IpStatsContext);

  const handlePickPlanMutation = useMutation({
    mutationFn: (value) => createCustomerOrder(value),
    onSuccess: ({ data }) => {
      handleSendIPstats(IP_STATS_ENUM.PLAN);
      localStorage.setItem("register_order", JSON.stringify(data));
      setPlan(data);
      setView(2);
    },
  });

  const handleCreateOrder = (e, type) => {
    e.preventDefault();

    window?.fbq("track", "InitiateCheckout");
    window?.uetq?.push("event", "begin_checkout", {
      revenue_value:
        parseFloat(selectedOption.full_price.replace(",", ".")) * 100,
      currency: "PLN",
    });

    const payload = {};
    payload.user = user._id;
    payload.plan_type = selectedOption.type;
    payload.price =
      parseFloat(selectedOption.full_price.replace(",", ".")) * 100;
    payload.payment_type = type;

    handlePickPlanMutation.mutate(payload);
  };

  return (
    <Wrapper>
      <Card>
        <Title>{english.register.plan.title}</Title>
        <PricesWrapper>
          <FlexElement onClick={() => setSelectedOption(CONFIG_DATA["_1"])}>
            <MonthWrapper>1 {english.register.plan.month}</MonthWrapper>
            <Border />
            <FullPriceWrapper>
              <b>{CONFIG_DATA["_1"].price}</b> {english.register.plan.value}
            </FullPriceWrapper>
            <div style={{ marginTop: "-5px" }}></div>
            {selectedOption.full_price === CONFIG_DATA["_1"].full_price ? (
              <PickerPicked>
                <img src={CheckWhite} />
              </PickerPicked>
            ) : (
              <Picker>{english.register.plan.pick}</Picker>
            )}
            <div style={{ marginTop: "-5px" }}></div>
            <NowUwillPay>{english.register.plan.nowPay}</NowUwillPay>
            <NowUwillPay>{CONFIG_DATA["_1"].full_price} zł</NowUwillPay>
          </FlexElement>
          <FlexElement
            onClick={() => setSelectedOption(CONFIG_DATA["_2"])}
            style={{
              border: "2px solid #3280FC",
            }}
          >
            <ElementTop>{english.register.plan.saveOne} 20% !</ElementTop>
            <MonthWrapper>3 {english.register.plan.months} </MonthWrapper>
            <Border style={{ marginTop: "-15px" }} />
            <FullPriceWrapperPercent>
              49,99 {english.register.plan.value}
            </FullPriceWrapperPercent>
            <FullPriceWrapper>
              <b>{CONFIG_DATA["_2"].price}</b> {english.register.plan.value}
            </FullPriceWrapper>
            {selectedOption.full_price === CONFIG_DATA["_2"].full_price ? (
              <PickerPicked>
                <img src={CheckWhite} />
              </PickerPicked>
            ) : (
              <Picker>{english.register.plan.pick}</Picker>
            )}
            <div style={{ marginTop: "-5px" }}></div>
            <NowUwillPay> {english.register.plan.nowPay}</NowUwillPay>
            <NowUwillPay>{CONFIG_DATA["_2"].full_price} zł</NowUwillPay>
          </FlexElement>
          <FlexElement
            onClick={() => setSelectedOption(CONFIG_DATA["_3"])}
            style={{
              border: "2px solid #3280FC",
            }}
          >
            <ElementTop>{english.register.plan.saveOne} 40% !</ElementTop>
            <MonthWrapper>6 {english.register.plan.months2}</MonthWrapper>
            <Border style={{ marginTop: "-15px" }} />
            <FullPriceWrapperPercent>
              49,99 {english.register.plan.value}
            </FullPriceWrapperPercent>
            <FullPriceWrapper>
              <b>{CONFIG_DATA["_3"].price}</b> {english.register.plan.value}
            </FullPriceWrapper>
            {selectedOption.full_price === CONFIG_DATA["_3"].full_price ? (
              <PickerPicked>
                <img src={CheckWhite} />
              </PickerPicked>
            ) : (
              <Picker>{english.register.plan.pick}</Picker>
            )}
            <div style={{ marginTop: "-5px" }}></div>
            <NowUwillPay>{english.register.plan.nowPay}</NowUwillPay>
            <NowUwillPay>{CONFIG_DATA["_3"].full_price} zł</NowUwillPay>
          </FlexElement>
        </PricesWrapper>
        {/* Mobile */}
        <PricesWrapperMobile>
          <FlexElementMobile
            onClick={() => setSelectedOption(CONFIG_DATA["_3"])}
            style={{
              border: "1px solid #3280FC",
            }}
          >
            <ElementTop style={{ width: "120px" }}>
              {english.register.plan.saveOne} 40% !
            </ElementTop>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  width: "150px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MonthWrapper>6 {english.register.plan.months2}</MonthWrapper>
                <Border />
                <NowUwillPay style={{ margin: "15px 0 0 0" }}>
                  {english.register.plan.nowPay}
                </NowUwillPay>
                <NowUwillPay>{CONFIG_DATA["_3"].full_price} zł</NowUwillPay>
              </div>
              <div
                style={{
                  width: "150px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FullPriceWrapperPercent>
                  49,99 {english.register.plan.value}
                </FullPriceWrapperPercent>
                <FullPriceWrapper>
                  <b>{CONFIG_DATA["_3"].price}</b> {english.register.plan.value}
                </FullPriceWrapper>
                {selectedOption.full_price === CONFIG_DATA["_3"].full_price ? (
                  <PickerPicked>
                    <img src={CheckWhite} />
                  </PickerPicked>
                ) : (
                  <Picker>{english.register.plan.pick}</Picker>
                )}
              </div>
            </div>
          </FlexElementMobile>
          <FlexElementMobile
            onClick={() => setSelectedOption(CONFIG_DATA["_2"])}
            style={{
              border: "1px solid #3280FC",
              background: " #E2FBF4",
            }}
          >
            <ElementTop style={{ width: "120px" }}>
              {english.register.plan.saveOne} 20% !
            </ElementTop>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  width: "150px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MonthWrapper>3 {english.register.plan.months}</MonthWrapper>
                <Border />
                <NowUwillPay style={{ margin: "15px 0 0 0" }}>
                  {english.register.plan.nowPay}
                </NowUwillPay>
                <NowUwillPay>{CONFIG_DATA["_2"].full_price} zł</NowUwillPay>
              </div>
              <div
                style={{
                  width: "150px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FullPriceWrapperPercent>
                  49,99 {english.register.plan.value}
                </FullPriceWrapperPercent>
                <FullPriceWrapper>
                  <b>{CONFIG_DATA["_2"].price}</b> {english.register.plan.value}
                </FullPriceWrapper>
                {selectedOption.full_price === CONFIG_DATA["_2"].full_price ? (
                  <PickerPicked>
                    <img src={CheckWhite} />
                  </PickerPicked>
                ) : (
                  <Picker>{english.register.plan.pick}</Picker>
                )}
              </div>
            </div>
          </FlexElementMobile>
          <FlexElementMobile
            onClick={() => setSelectedOption(CONFIG_DATA["_1"])}
            style={{
              border: "1px solid #3280FC",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  width: "150px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MonthWrapper style={{ marginTop: "0" }}>
                  1 {english.register.plan.month}
                </MonthWrapper>
                <Border />
                <NowUwillPay style={{ margin: "15px 0 0 0" }}>
                  {english.register.plan.nowPay}
                </NowUwillPay>
                <NowUwillPay>{CONFIG_DATA["_1"].full_price} zł</NowUwillPay>
              </div>
              <div
                style={{
                  width: "150px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FullPriceWrapper style={{ marginTop: "20px" }}>
                  <b>{CONFIG_DATA["_1"].price}</b> {english.register.plan.value}
                </FullPriceWrapper>
                {selectedOption.full_price === CONFIG_DATA["_1"].full_price ? (
                  <PickerPicked>
                    <img src={CheckWhite} />
                  </PickerPicked>
                ) : (
                  <Picker>{english.register.plan.pick}</Picker>
                )}
              </div>
            </div>
          </FlexElementMobile>
        </PricesWrapperMobile>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <PaymentElement
            id="initiate_checkout"
            style={{ margin: "10px 0" }}
            onClick={(e) => handleCreateOrder(e, PAYMENT_FORMS[0].type)}
          >
            {english.register.plan.buy}
          </PaymentElement>
          <PaymentTypeWrapper>
            <PaymentTitle>
              <img src={Lock} /> {english.register.plan.savePayment}{" "}
              <PaymentIcon src={PayuIcon} />
            </PaymentTitle>
            <PaymentText
              style={{
                borderRadius: "4px",
                border: "1px solid rgba(79, 167, 180, 0.20)",
                display: "flex",
                padding: "5px",
                gap: "20px",
              }}
            >
              <PaymentIcon src={Blik} style={{ width: "30px" }} />
              <PaymentIcon
                src={Visa}
                style={{
                  width: "30px",
                }}
              />
              <PaymentIcon
                src={MasterCard}
                style={{
                  width: "20px",
                }}
              />
              <PaymentIcon
                src={Transfer}
                style={{
                  width: "45px",
                }}
              />
            </PaymentText>
          </PaymentTypeWrapper>
        </div>
      </Card>
    </Wrapper>
  );
};

export default PlanView;
