import { styled } from "styled-components";

export const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;

  @media (min-width: 800px) {
    display: none;
  }
`;

export const WrapperSchema = styled.div`
  display: flex;
  width: 100%;
  margin: -60px 0 -33px 0;
  z-index: 1;
`;

export const ImageWrapper = styled.div``;

export const StartButton = styled.a`
  display: inline-flex;
  text-decoration: none;
  padding: 12px 80px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border-radius: 50px;
  background: linear-gradient(266deg, #3280fc -13.87%, #3db2dc 91.82%);
  border: none;
  color: #fff;
  text-align: center;
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.96px;
  text-transform: uppercase;

  &:hover {
    transform: scale(1.05);
  }

  @media (min-width: 600px) {
    width: 350px;
  }
`;

export const TextInformation = styled.p`
  color: #0b2f45;
  text-align: center;
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 300px;

  @media (min-width: 600px) {
  }
`;

export const SectionTitle = styled.h2`
  margin-top: 70px;
  color: #05275d;
  text-align: center;
  /* H2 */
  font-family: Work Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.8px;

  @media (max-width: 600px) {
    display: none;
  }
`;
export const StarsImage = styled.img`
  width: 25px;
  height: 120px;
  margin-top: 25px;

  @media (max-width: 600px) {
    height: 70px;
    margin-top: 17px;
    margin-right: -10px;
  }
`;

export const CustomImage = styled.img`
  width: 100%;
  height: auto;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
  flex-wrap: wrap;
  gap: 200px;

  @media (max-width: 600px) {
    gap: 20px;
  }
`;

export const SectionTitleMobile = styled(SectionTitle)`
  display: none;

  @media (max-width: 600px) {
    color: #05275d;
    font-family: Work Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.48px;
    display: flex;
    margin: 30px 0 0px;
  }
`;

export const ListWrapper = styled.div`
  display: flex;
  width: 472px;
  height: 216px;
  gap: 15px;

  @media (max-width: 600px) {
    width: 250px;
    height: fit-content;
  }
`;

export const ListText = styled.p`
  color: #05275d;
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.3%; /* 31.992px */

  @media (max-width: 600px) {
    font-size: 14px;
    max-width: 210px;
  }
`;
