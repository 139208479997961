import React, { useContext, useEffect, useRef } from "react";
import Computer from "./images/computer.png";
import styled from "styled-components";
import Video from "./video/sarai-demo.mp4";
import { IpStatsContext } from "@/common/contexts/IpStatsContext";
import { IP_STATS_ENUM } from "@/common/enums/ipStatsEnum";
import SaraiDekstopDark from "./images/ai_photo_desktop.png";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
  overflow: hidden;

  @media (max-width: 600px) {
    display: none;
  }

  @media (max-width: 1000px) {
    margin-bottom: 0;
  }
`;

const ImageWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const Title = styled.h1`
  background: linear-gradient(
    273deg,
    #3eb2dc 1.89%,
    #3daede 1.9%,
    #8befd2 102.52%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-family: Work Sans;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.96px;
  margin: 0;
  text-align: center;
  width: 550px;
  margin-top: 100px;
`;

export const Subtitle = styled.h3`
  color: #0b2f45;
  text-align: center;
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 100px;
`;

export const StartButton = styled.a`
  display: inline-flex;
  padding: 12px 60px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: linear-gradient(266deg, #3280fc -13.87%, #3db2dc 91.82%);
  border: none;
  color: #fff;
  text-align: center;
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.96px;
  text-transform: uppercase;
  text-decoration: none;
  width: 250px;

  &:hover {
    transform: scale(1.05);
  }
`;

const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  margin-bottom: 100px;
  margin-left: 450px;
  z-index: 1;

  @media (max-width: 1000px) {
    margin: 0;
  }
`;

const Subtitle2 = styled.p`
  color: #b5b3bc !important;
  text-align: center;
  font-family: "Work Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  max-width: 300px;

  margin: 10px 0 70px 0;
`;

const HeroSectionDesktop = ({ data }) => {
  const { handleSendIPstats } = useContext(IpStatsContext);

  return (
    <Wrapper>
      <ImageWrapper>
        <img src={SaraiDekstopDark} />
      </ImageWrapper>
      <RightWrapper>
        <Title>{data.title}</Title>
        <Subtitle2>{data.subtitle2}</Subtitle2>
        <StartButton
          href="#plan-offer"
          onClick={() => {
            handleSendIPstats(IP_STATS_ENUM.REGISTER_BY_MIDDLE);
            window.gtag("event", "clicked_buy_now_hero");
          }}
        >
          {data.buyNow}
        </StartButton>
      </RightWrapper>
    </Wrapper>
  );
};

export default HeroSectionDesktop;
